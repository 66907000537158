<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="primary" class="white--text">
      {{ loc_emp.lname }}, {{ loc_emp.fname }}
      <template v-slot:actions>
        <v-icon color="secondary"> $chevup </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="background-color: whitesmoke">
      <v-container>
        <v-row dense>
          <v-col col="12">
            <template>
              <h6>Completion</h6>
              <v-progress-linear
                :value="completed"
                :color="completed == 100 ? 'green' : 'black'"
              >
              </v-progress-linear>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="loc_emp.s_del != 't'">
            <v-btn rounded color="secondary" @click.prevent="open_diag_edit">
              <v-icon left> $edit </v-icon>
              Edit
            </v-btn>

            <v-btn
              v-if="loc_emp.s_del == 'f'"
              @click.prevent="open_diag_delete"
              rounded
              color="secondary"
              class="ml-2"
            >
              <v-icon left> $success </v-icon>
              Complete
            </v-btn>
            <!-- <v-btn
              v-if="loc_emp.s_del == 't'"
              @click.prevent="UN_Complete"
              rounded
              color="secondary"
              class="ml-2"
            >
              <v-icon left> $success </v-icon>
              UN-Complete
            </v-btn> -->
          </v-col>
        </v-row>
        <v-row dense class="moredemo">
          <v-col col="12">
            <v-card elevation="2" outlined style="width: 100%">
              <v-card-text>
                <v-row>
                  <v-col cols="6" md="6">
                    <Label>Hire Authority Name:</Label>
                    <article class="dataitem">
                      {{ loc_emp.ha_fullname }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="6">
                    <Label>Hire Authority Email:</Label>
                    <article class="dataitem">
                      {{ loc_emp.hire_auth_email }}
                    </article>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <h3>EMPLOYEE</h3>
                    <hr />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="3">
                    <!-- <h4>{{loc_emp.lst_items[0].title}}</h4>  -->
                    <Label>First Name:</Label>
                    <article class="dataitem">
                      {{ loc_emp.fname }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3">
                    <Label>Last Name:</Label>
                    <article class="dataitem">
                      {{ loc_emp.lname }}
                    </article>
                  </v-col>
                  <!-- <v-col cols="6" md="3">
                    <Label>DOB:</Label>
                    <article class="dataitem">
                      {{ loc_emp.dob }}
                    </article>
                  </v-col> -->
                  <v-col cols="6" md="3" v-if="loc_emp.s_del == 't'">
                    <Label>Completed:</Label>
                    <article class="dataitem">
                      {{ loc_emp.s_date | hide111900 }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Start Date:</Label>
                    <article class="dataitem">
                      {{ loc_emp.st_date | hide111900 }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Start Date:</Label>
                    <article class="dataitem">
                      {{ loc_emp.st_date | hide111900 }}
                    </article>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Supervisor:</Label>
                    <article class="dataitem">
                      {{ loc_emp.supervisor }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Personal Cell:</Label>
                    <article class="dataitem">
                      {{ loc_emp.persons_cell }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Work Cell:</Label>
                    <article class="dataitem">
                      {{ loc_emp.work_cell }}
                    </article>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="4" v-if="loc_emp.s_del != 't'">
                    <Label>Work Email:</Label>
                    <article class="dataitem">
                      {{ loc_emp.work_email }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="4" v-if="loc_emp.s_del != 't'">
                    <Label>Personal Email:</Label>
                    <article class="dataitem">
                      {{ loc_emp.personal_email }}
                    </article>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Emp. Type:</Label>
                    <article class="dataitem">
                      {{ loc_emp.emp_type }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Unit:</Label>
                    <article class="dataitem">
                      {{ loc_emp.unit }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Division:</Label>
                    <article class="dataitem">
                      {{ loc_emp.division }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3" v-if="loc_emp.s_del != 't'">
                    <Label>Status:</Label>
                    <article
                      class="dataitem"
                      :style="{ color: loc_emp.s_del == 'f' ? 'gray' : 'red' }"
                      v-text="loc_emp.s_del == 'f' ? 'Pending' : 'Completed'"
                    ></article>
                  </v-col>
                </v-row>
                <v-row v-if="loc_emp.s_del != 't'">
                  <v-col cols="12" md="12">
                    <h3>ADMIN</h3>
                    <hr />
                  </v-col>
                </v-row>
                <v-row v-if="loc_emp.s_del != 't'">
                  <v-col cols="6" md="3">
                    <Label>TCN#:</Label>
                    <article class="dataitem">
                      {{ loc_emp.finger_print_num }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3">
                    <Label>Access Card#:</Label>
                    <article class="dataitem">
                      {{ loc_emp.access_card_number }}
                    </article>
                  </v-col>
                  <v-col cols="6" md="3">
                    <Label>Badge/Emp#:</Label>
                    <article class="dataitem">
                      {{ loc_emp.badge_emp_num }}
                    </article>
                  </v-col>
                </v-row>
                <v-row v-if="loc_emp.s_del != 't'">
                  <v-col cols="12">
                    <Label>Notes (Clearance Needed):</Label>
                    <article class="dataitem">
                      {{ loc_emp.notes }}
                    </article>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense v-if="show_items && loc_emp.s_del != 't'">
          <v-col cols="12">
            <div class="mt-2">Click on items to complete</div>
            <!--list of items!!!-->

            <v-card
              v-for="item in loc_emp.lst_newemp_items"
              :key="item.newemp_itemId"
              style="cursor: pointer"
            >
              <v-card-actions @click.prevent="toggle_item(item)" class="mb-2">
                <v-row dense>
                  <v-col cols="2" md="1">
                    <v-icon left x-large :color="item.done ? 'green' : 'red'">
                      {{ item.done ? "$checkedcircle" : "$delete" }}
                    </v-icon>
                  </v-col>
                  <v-col cols="5" md="4">
                    <article class="dataitem" v-html="item.title"></article>
                  </v-col>
                  <v-col cols="5" md="3">
                    <article
                      class="dataitem"
                      v-text="filter_nodate(item.assign_date)"
                    ></article>
                  </v-col>
                  <v-col v-if="item.done" md="4" class="d-none d-sm-flex">
                    <article>
                      {{ item.s_user }}
                    </article>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
label {
  font-weight: 500;
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "emp_panel",
  mixins: [bus_common],
  props: ["emp", "lst_items"],
  data() {
    return {
      refreshkey: 0, // fake property to force recompute
      show_items: true,
      loc_emp: [],
      panel: 0,
      panel2: 0,
    };
  },
  watch: {
    emp: function () {
      if (this.emp != undefined && this.emp != null) {
        this.init();
      }
    },
    loc_emp: {
      handler() {
        //   console.log('loc_emp.lst_newemp_items change ' + this.completed);
      },
      deep: true,
    },
  },
  computed: {
    completed() {
      var r = this.refreshkey;
      if (this.loc_emp.lst_newemp_items != undefined) {
        let cnt = this.loc_emp.lst_newemp_items.length;
        let doneTots = this.loc_emp.lst_newemp_items.reduce(function (
          prev,
          cur
        ) {
          let val = cur.done ? 1 : 0;
          return prev + val;
        },
        0);
        return parseInt((doneTots / cnt) * 100);
      } else {
        return 0;
      }
    },
  },
  filters: {
    ameridate(value) {
      if (value == undefined) {
        return "";
      }
      return new Date(value).toLocaleDateString();
    },
    hide111900(value) {
      if (value == "0001-01-01" || value == "1900-01-01") {
        return "";
      } else {
        return value;
      }
    },
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    UN_Complete() {
      this.$emit("un_delete_emp", this.loc_emp.new_empId);
    },
    open_diag_delete() {
      let self = this;
      var name = this.loc_emp.fname + " " + this.loc_emp.lname;
      this.swall_diag(
        "Complete new employee?",
        "Do you wish to complete the process for '" +
          name +
          "' and notify all admins?",
        "question",
        function () {
          //yes
          self.$emit("delete_emp", self.loc_emp.new_empId);
        },
        null,
        null,
        function () {
          //cancel do nothing
        }
      );
    },
    open_diag_edit() {
      this.$emit("open_diag_edit", this.loc_emp);
    },
    filter_nodate(value) {
      if (value === "1900-01-01") {
        return "No Date";
      } else {
        return value;
      }
    },
    toggle_item(item) {
      this.show_loader(true);
      var dateNow = new Date();
      var dateThen = new Date(item.s_date);
      if (this.getDifferenceInSeconds(dateNow, dateThen) <= 180) {
        this.swall_it(
          "Not Allowed",
          "To prevent accidents, items may only be changed once every 3 minutes.",
          "warning",
          null,
          null
        );
        return;
      }

      item.done = !item.done;
      let formIn = this.mno(item);
      formIn.apiname = "save_chk_item";
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.done = item.done;

      let self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.show_loader(false);

          // item = self.mno(data);
          self.show_items = false;

          data.assign_date = this.$moment(new Date(data.assign_date)).format(
            "yyyy-MM-DD"
          );
          data.appname = "bleh";

          var indx = self.loc_emp.lst_newemp_items.findIndex((object) => {
            return object.newemp_itemId === data.newemp_itemId;
          });

          self.$nextTick(() => {
            if (indx != -1) {
              self.loc_emp.lst_newemp_items[indx] = data;
              self.refreshkey++;
              self.show_items = true;
            }
          });
        })
        .catch((msg) => {
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
        });
    },
    init() {
      this.loc_emp = this.mno(this.emp);

      this.loc_emp.dob = this.$moment(new Date(this.loc_emp.dob)).format(
        "yyyy-MM-DD"
      );

      this.loc_emp.s_date = this.$moment(new Date(this.loc_emp.s_date)).format(
        "yyyy-MM-DD"
      );
      this.loc_emp.st_date = this.$moment(
        new Date(this.loc_emp.st_date)
      ).format("yyyy-MM-DD");

      this.loc_emp.lst_newemp_items.forEach((item, index) => {
        item.assign_date = this.$moment(new Date(item.assign_date)).format(
          "yyyy-MM-DD"
        );
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.emp != undefined && this.emp != null) {
        this.init();
      }
    });
  },
};
</script>
